import React from 'react';

function PageNotFound() {
	return (
		<div className='container m-5'>
			<div className='row p-5'>
				<h1 className='d-flex justify-content-center pb-5 text-primary display-5'>Ops! Nothing found here</h1>
			</div>
		</div>
	);
}

export default PageNotFound;
