function ComponentsController() {
	return {
		HomeEnabled: true,
		AboutEnabled: true,
		ContactEnabled: true,
		NotFoundEnabled: true,
		DevToPostsEnabled: true,
		DevToSinglePostEnabled: true,
		GithubReposEnabled: true,
		WPPostsListEnabled: true,
		WPSinglePostEnabled: true
	};
}

export default ComponentsController;
