import React from 'react';
import ReposList from '../components/src-github/repos-list';

function GithubRepos() {
	return (
		<div className='container m-5'>
			<div className='row p-5'>
				<h1 className='d-flex justify-content-center pb-5 text-primary display-5'>Code Samples</h1>
				<ReposList />
			</div>
		</div>
	);
}

export default GithubRepos;
