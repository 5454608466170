import React from 'react';
import './assets/css/main.scss';
import Router from './utils/router';
import { UserInfo } from './utils/user-info';
import Header from './components/layout/header';
import ScrollIndicator from './components/layout/scroll-indicator';
import Footer from './components/layout/footer';

function App() {
	return (
		<UserInfo
			projectName='Octopus Headless Connect'
			heading='My Heading'
			description='This is a description.'
			linkedinUsername='sarahcssiqueira'
			githubUsername='sarahcssiqueira'
			twitterUsername='sarahcssiqueira'
			devToUsername='sarahcssiqueira'
			emailAddress='sarahcosiqueira@gmail.com'
			wordpressURL='https://blog.sarahjobs.com'
		>
			<Header />
			<ScrollIndicator />
			<Router />
			<Footer />
		</UserInfo>
	);
}

export default App;
