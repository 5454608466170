import React from 'react';
import SingleWPPost from '../components/src-wp/single-post';

function WPSinglePost() {
	return (
		<main className='container mb-5'>
			<SingleWPPost />
		</main>
	);
}

export default WPSinglePost;
