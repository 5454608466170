/* eslint-disable react/react-in-jsx-scope */
import { Routes, Route } from 'react-router-dom';
import ComponentsController from './components-controller';

/* Templates */
import Home from '../templates/homepage';
import About from '../templates/about';
import Contact from '../templates/contact';
import NotFound from '../templates/404';
import DevToPosts from '../templates/devto-posts';
import DevToSinglePost from '../templates/devto-single-post';
import GithubRepos from '../templates/github-repos';
import WPPostsList from '../templates/wp-posts';
import WPSinglePost from '../templates/wp-single-posts';

function Router() {
	const components = ComponentsController();

	return (
		<Routes>
			<Route index element={components.HomeEnabled ? <Home /> : <NotFound />} />
			<Route path='about' element={components.AboutEnabled ? <About /> : <NotFound />} />
			<Route path='contact' element={components.ContactEnabled ? <Contact /> : <NotFound />} />
			<Route path='blog' element={components.DevToPostsEnabled ? <DevToPosts /> : <NotFound />} />
			<Route path='/post/:slug/:id' element={components.DevToSinglePostEnabled ? <DevToSinglePost /> : <NotFound />} />
			<Route path='code-samples' element={components.GithubReposEnabled ? <GithubRepos /> : <NotFound />} />
			<Route path='wp-posts' element={components.WPPostsListEnabled ? <WPPostsList /> : <NotFound />} />
			<Route path='/wp-post/:slug/:id' element={components.WPSinglePostEnabled ? <WPSinglePost /> : <NotFound />} />
			<Route path='*' element={<NotFound />} />
		</Routes>
	);
}

export default Router;
