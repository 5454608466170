import React from 'react';
import { useUserData } from '../utils/user-info';
import { Link } from 'react-router-dom';

function PageContact() {
	const userData = useUserData();

	return (
		<main className='container m-5'>
			<h1 className='d-flex justify-content-center pb-5 text-primary display-5'>Contact Me</h1>
			<section className='row p-5'>
				<Link
					to={userData.socialMedia.github}
					target='_blank'
					rel='noopener noreferrer'
					className='d-flex justify-content-center text-decoration-none text-primary'
				>
					<i className='fa-brands fa-github text-primary'></i>
					<p>Github</p>
				</Link>

				<Link
					to={userData.socialMedia.linkedin}
					target='_blank'
					rel='noopener noreferrer'
					className='text-decoration-none d-flex justify-content-center text-primary'
				>
					<i className='fa-brands fa-linkedin-in text-primary'></i>
					<p>Reach me on Linkedin</p>
				</Link>

				<Link
					to={userData.socialMedia.devTo}
					target='_blank'
					rel='noopener noreferrer'
					className='text-decoration-none d-flex justify-content-center text-primary'
				>
					<i className='fa-brands fa-dev text-primary'></i>
					<p>Dev.to</p>
				</Link>

				<Link
					to={`mailto:${userData.contactInfo.email}`}
					target='_blank'
					rel='noopener noreferrer'
					className='text-decoration-none d-flex justify-content-center text-primary'
				>
					<i className='fa-solid fa-envelope text-primary'></i>
					<p>{userData.socialMedia.email}</p>
				</Link>
			</section>
		</main>
	);
}

export default PageContact;
