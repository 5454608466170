import React, { useEffect, useState } from 'react';
import { useUserData } from '../../utils/user-info';
import { Link } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { AiFillStar } from 'react-icons/ai';
import { BiGitRepoForked } from 'react-icons/bi';

function ReposList({ extraParam = '' }) {
	const [repo, setRepo] = useState([]);
	const userData = useUserData();

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData, extraParam]);

	const fetchData = async () => {
		try {
			const res = await axios.get(`${userData.endpoint.github}${extraParam}`);
			setRepo(res.data);
			//console.log(res.data);
		} catch (error) {
			//console.log('Error: ', error);
		}
	};

	return (
		<>
			{repo.map(({ id, topics, svn_url, name, description, stargazers_count, forks_count }) => (
				<article key={id} className='col-12 col-sm-6 col-md-4 my-4'>
					<div className='card card-body'>
						<Link to={svn_url} className='text-decoration-none d-flex justify-content-center mb-3'>
							{name.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}
						</Link>

						<ul className='d-flex justify-content-around flex-wrap'>
							{topics.map((topic, index) => (
								<li key={index} className='badge bg-primary mb-1'>
									<Link
										to={`https://github.com/topics/${topic}`}
										className='text-decoration-none text-white'
										target='_blank'
										rel='noopener noreferrer'
									>
										{topic}
									</Link>
								</li>
							))}
						</ul>

						<p className='card-body text-secondary'>{description}</p>

						<div className='d-flex justify-content-center'>
							<ul className='row'>
								<li className='col'>
									<AiFillStar />
									<p>{stargazers_count}</p>
								</li>

								<li className='col'>
									<BiGitRepoForked />
									<p>{forks_count}</p>
								</li>
							</ul>
						</div>

						<div className='btn btn-primary'>
							<Link to={svn_url} className='link-light text-decoration-none text-white'>
								View on Github
							</Link>
						</div>
					</div>
				</article>
			))}
		</>
	);
}

export default ReposList;

ReposList.propTypes = {
	extraParam: PropTypes.string
};
