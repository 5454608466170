import React from 'react';
import SinglePost from '../components/src-devto/single-post';

function DevToSinglePost() {
	return (
		<main className='container mb-5'>
			<SinglePost />
		</main>
	);
}

export default DevToSinglePost;
