import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useUserData } from '../../utils/user-info';
import axios from 'axios';
import PropTypes from 'prop-types';
import ImgPlaceholder from '../../assets/img/post-image-placeholder.jpg';

function WPPostsList({ postType = 'posts' }) {
	const [posts, setPosts] = useState([]);
	const userData = useUserData();

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchData = async () => {
		try {
			const res = await axios.get(`${userData.endpoint.wordpressURL}/wp-json/wp/v2/${postType}`);
			setPosts(res.data);
			//console.log(res.data);
		} catch (error) {
			//console.log('Error: ', error);
		}
	};

	if (!posts) {
		// Handle loading state
		return <p>Loading...</p>;
	}

	return (
		<>
			{posts.map(({ id, slug, title, content, better_featured_image }) => (
				<article key={id} className='col-12 col-sm-6 col-md-4 my-4'>
					<div className='card card-body'>
						<Link to={`../wp-post/${slug}/${id}`} className='text-decoration-none'>
							<img
								src={better_featured_image?.source_url ? better_featured_image.source_url : ImgPlaceholder}
								className='img-fluid p-3'
							></img>
							<p className='d-flex justify-content-center mb-3'>{title.rendered}</p>
						</Link>
						<span dangerouslySetInnerHTML={{ __html: content.rendered }} className='card-body text-secondary'></span>
					</div>
				</article>
			))}
		</>
	);
}

export default WPPostsList;

WPPostsList.propTypes = {
	postType: PropTypes.string
};
