import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const UserData = createContext();

export const useUserData = () => useContext(UserData);

export const UserInfo = ({
	children,
	projectName,
	heading,
	description,
	linkedinUsername,
	githubUsername,
	twitterUsername,
	devToUsername,
	emailAddress,
	wordpressURL
}) => {
	const userData = {
		projectName: projectName,
		heading: heading,
		description: description,
		socialMedia: {
			linkedin: `https://www.linkedin.com/in/${linkedinUsername} `,
			github: `https://github.com/${githubUsername}`,
			twitter: `https://twitter.com/${twitterUsername}`,
			devTo: `https://dev.to/${devToUsername}`
		},
		contactInfo: {
			email: emailAddress
		},
		endpoint: {
			devTo: `https://dev.to/api/articles?username=${devToUsername}`,
			github: `https://api.github.com/users/${githubUsername}/repos`,
			wordpressURL: wordpressURL
		}
	};

	return <UserData.Provider value={userData}>{children}</UserData.Provider>;
};

UserInfo.propTypes = {
	children: PropTypes.array,
	projectName: PropTypes.string,
	heading: PropTypes.string,
	description: PropTypes.string,
	username: PropTypes.string,
	linkedinUsername: PropTypes.string,
	githubUsername: PropTypes.string,
	twitterUsername: PropTypes.string,
	devToUsername: PropTypes.string,
	emailAddress: PropTypes.string,
	wordpressURL: PropTypes.string
};
