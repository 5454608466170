import React from 'react';
import WPPostsList from '../components/src-wp/posts-list';

function WPPosts() {
	return (
		<main className='container m-5'>
			<div className='row p-5'>
				<h1 className='d-flex justify-content-center pb-5 text-primary display-5'>WP Posts</h1>
				<WPPostsList />
			</div>
		</main>
	);
}

export default WPPosts;
