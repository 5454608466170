import React from 'react';
import profilePic from '../assets/img/profilepic.png';

function PageAbout() {
	return (
		<main className='container m-5'>
			<div className='row p-5'>
				<h1 className='d-flex justify-content-center pb-5 text-primary display-5'>About</h1>
				<div>
					<figure className='col'>
						<img src={profilePic} alt='profile' height='450' width='450' className='img-fluid d-flex justify-content-center' />
					</figure>

					<article className='col'>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ac metus velit. Nullam ullamcorper mollis velit, sed mollis orci
						porttitor ac. Suspendisse ultricies tortor quis lorem iaculis vehicula. Nam sollicitudin vehicula lectus, molestie maximus orci
						imperdiet sit amet. Duis congue nisl eu hendrerit egestas. Phasellus laoreet, dui a pellentesque consectetur, felis felis
						pulvinar lacus, eget laoreet diam lectus at sapien. Nunc felis metus, iaculis mollis mattis non, semper vel est. Donec interdum
						aliquet euismod. Nullam id lacinia libero. Cras suscipit eros eget ex vestibulum elementum at et lorem. Donec volutpat ac elit
						sit amet placerat. Suspendisse tempus pharetra enim. Donec in elit sed enim accumsan ultrices a vitae lectus. Integer eu eros
						bibendum, venenatis orci molestie, venenatis nibh. Ut feugiat justo vitae quam tempor volutpat at sed nibh. Curabitur imperdiet
						placerat leo, vel lacinia ante ornare nec. Aenean sem purus, tincidunt non leo at, faucibus pellentesque erat. Donec sed felis
						augue. Integer rhoncus eget turpis ac ultricies. Nullam consequat nisi est, vitae tincidunt ex varius vel. Praesent nec tempus
						nibh. Sed erat nulla, convallis eget rutrum non, malesuada vitae turpis. Fusce interdum libero et lacinia congue. Ut ut
						fermentum turpis. Ut tortor lectus, facilisis in ultricies id, porttitor eu felis. Sed et lacus vehicula ligula varius lobortis.
						Sed sem magna, dapibus ullamcorper cursus eu, blandit ornare neque. Quisque accumsan, libero ut tempor gravida, sapien magna
						luctus sem, ut viverra sem mi non turpis. Integer cursus diam et est dignissim consequat.
					</article>
				</div>
			</div>
		</main>
	);
}

export default PageAbout;
