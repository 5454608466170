import React from 'react';
import { useUserData } from '../utils/user-info';
import { Link } from 'react-router-dom';
import profilePic from '../assets/img/profilepic.png';
import PostsList from '../components/src-devto/posts-list';
import WPPostsList from '../components/src-wp/posts-list';
import ReposList from '../components/src-github/repos-list';

function Homepage() {
	const userData = useUserData();

	return (
		<>
			<section className='container'>
				<section className='row m-5 justify-content-center'>
					<div className='col'>
						<p className='mt-3 mb-3 d-flex justify-content-center'>{userData.projectName}</p>
						<h1 className='mt-3 mb-3 d-flex justify-content-center'>{userData.heading}</h1>
						<p className='mt-3 mb-3 d-flex justify-content-center'>{userData.description}</p>

						<div className='d-flex justify-content-center'>
							<button className='btn btn-primary m-2' title='about'>
								<Link to='/contact' className='text-decoration-none text-white'>
									Contact Me
								</Link>
							</button>
						</div>

						<div className='m-2 d-flex justify-content-center'>
							<Link className='p-2' to={`mailto:${userData.contactInfo.email}`} target='_blank' rel='noopener noreferrer'>
								<i className='fa-solid fa-envelope' />
							</Link>

							<Link className='p-2' to={userData.socialMedia.linkedin} target='_blank' rel='noopener noreferrer'>
								<i className='fa-brands fa-linkedin-in' />
							</Link>

							<Link className='p-2' to={userData.socialMedia.github} target='_blank' rel='noopener noreferrer'>
								<i className='fa-brands fa-github' />
							</Link>

							<Link className='p-2' to={userData.socialMedia.devTo} target='_blank' rel='noopener noreferrer'>
								<i className='fa-brands fa-dev' />
							</Link>
						</div>
					</div>

					<div className='col d-flex justify-content-center'>
						<figure className=''>
							<img src={profilePic} alt='profile' height='250' width='250' className='' />
						</figure>
					</div>
				</section>
			</section>

			<section className='container-fluid bg-light'>
				<div className='row m-5 p-5'>
					<h1 className='d-flex justify-content-center my-3'>Dev Communnity</h1>
					<PostsList extraParam='&per_page=3' />
				</div>

				<div className='row m-5 p-5'>
					<h1 className='d-flex justify-content-center my-3'>WordPress Posts</h1>
					<WPPostsList postType='posts' />
				</div>

				<div className='row m-5 p-5'>
					<h1 className='d-flex justify-content-center my-3'>WordPress Pages</h1>
					<WPPostsList postType='pages' />
				</div>

				<div className='row m-5 p-5'>
					<h1 className='d-flex justify-content-center my-3'>GitHub Repositories</h1>
					<ReposList extraParam='?per_page=3' />
				</div>
			</section>
		</>
	);
}

export default Homepage;
