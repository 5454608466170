import React, { useEffect, useState } from 'react';
import { useUserData } from '../../utils/user-info';
import { Link } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { BsHeartFill } from 'react-icons/bs';
import { BsFillChatFill } from 'react-icons/bs';

function PostsList({ extraParam = '' }) {
	const [posts, setPosts] = useState([]);
	const userData = useUserData();

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchData = async () => {
		try {
			const res = await axios.get(`${userData.endpoint.devTo}${extraParam}`);
			setPosts(res.data);
			//console.log(res.data);
		} catch (error) {
			//console.log('Error: ', error)
		}
	};

	return (
		<>
			{posts.map(
				({
					id,
					slug,
					cover_image,
					title,
					tag_list,
					readable_publish_date,
					reading_time_minutes,
					public_reactions_count,
					comments_count
				}) => (
					<article key={id} className='col-12 col-sm-6 col-md-4'>
						<div className='card card-body m-1'>
							<Link to={`../post/${slug}/${id}`} className=''>
								<img src={cover_image} alt={title} className='img-fluid card-img-top rounded'></img>
							</Link>

							<ul className='d-flex flex-wrap justify-content-center'>
								{tag_list.map((tag, index) => (
									<li key={index} className='badge bg-primary m-1'>
										<Link
											to={`https://dev.to/t/${tag}`}
											target='_blank'
											rel='noopener noreferrer'
											className='text-decoration-none text-white'
										>
											{tag}
										</Link>
									</li>
								))}
							</ul>

							<Link to={`../post/${slug}/${id}`} className='text-decoration-none d-flex justify-content-center mb-3'>
								{title.length > 50 ? `${title.substring(0, 50)}...` : title}
							</Link>

							<ul className='row'>
								<li className='col card-text'>{readable_publish_date}</li>
								<li className='col card-text'>{reading_time_minutes} min read</li>
								<li className='col'>
									{`${public_reactions_count}${' '}`}
									<BsHeartFill />
								</li>
								<li className='col'>
									{`${comments_count}${' '}`}
									<BsFillChatFill />
								</li>
							</ul>
						</div>
					</article>
				)
			)}
		</>
	);
}

export default PostsList;

PostsList.propTypes = {
	extraParam: PropTypes.string
};
