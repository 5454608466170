import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUserData } from '../../utils/user-info';
import axios from 'axios';
import { BsHeartFill } from 'react-icons/bs';
import { BsFillChatFill } from 'react-icons/bs';

function SinglePost() {
	const location = useLocation();
	const path = location.pathname.split('/')[3];
	const [post, setPost] = useState([]);
	const userData = useUserData();
	const finalEndpoint = userData.endpoint.devTo.split('?')[0];

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchData = async () => {
		try {
			const res = await axios.get(`${finalEndpoint}/${path}`);
			setPost(res.data);
			//	console.log(res.data);
		} catch (error) {
			//console.log('Error: ', error );
		}
	};

	const {
		id,
		tags = [],
		title,
		readable_publish_date,
		reading_time_minutes,
		public_reactions_count,
		comments_count,
		url,
		slug,
		cover_image,
		body_html
	} = post;

	return (
		<article key={id} className='row p-5'>
			<ul className=''>
				{tags.length > 0 ? (
					tags.map((tag, index) => (
						<li key={index} className='badge bg-primary m-1'>
							<Link to={`https://dev.to/t/${tag}`} target='_blank' rel='noopener noreferrer' className='text-decoration-none text-white'>
								{tag}
							</Link>
						</li>
					))
				) : (
					<li></li>
				)}
			</ul>

			<h2 className='p-2'>{title}</h2>

			<ul className='row p-2'>
				<li className='col'>Published on {readable_publish_date}</li>
				<li className='col'>{reading_time_minutes} min read</li>
				<li className='col'>
					{public_reactions_count} <BsHeartFill />
				</li>
				<li className='col'>
					{comments_count} <BsFillChatFill />
				</li>
				<li className='col'>
					Originally published on {''}
					<Link to={`${url}`} target='_blank' rel='noopener noreferrer' className='text-decoration-none'>
						Dev Community
					</Link>
				</li>
			</ul>

			<Link to={`../post/${slug}/${id}`} className='d-flex justify-content-center'>
				<img src={cover_image} alt={title} className='img-fluid p-3'></img>
			</Link>

			<p className='m-3' dangerouslySetInnerHTML={{ __html: body_html }} />
		</article>
	);
}

export default SinglePost;
