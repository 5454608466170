import React from 'react';
import { useUserData } from '../../utils/user-info';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Header() {
	const userData = useUserData();

	return (
		<div className='container-fluid'>
			<Navbar expand='lg' className='bg-light p-4'>
				<div className='d-flex w-100'>
					<Navbar.Brand href='/' className='ms-auto'>
						{userData.projectName}
					</Navbar.Brand>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='ms-auto'>
							<Nav.Link href='/'>Home</Nav.Link>
							<Nav.Link href='/blog'>Blog</Nav.Link>
							<Nav.Link href='/code-samples'>Code Samples</Nav.Link>
							<Nav.Link href='/wp-posts'>WordPress Posts</Nav.Link>
							<Nav.Link href='/about'>About</Nav.Link>
							<Nav.Link href='/contact'>Contact</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</div>
			</Navbar>
		</div>
	);
}

export default Header;
