import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useUserData } from '../../utils/user-info';
import PropTypes from 'prop-types';

function WPSinglePost({ postType = 'posts' }) {
	const location = useLocation();
	const path = location.pathname.split('/')[3];
	const [post, setPost] = useState(null);
	const userData = useUserData();

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchData = async () => {
		try {
			const res = await axios.get(`${userData.endpoint.wordpressURL}/wp-json/wp/v2/${postType}/${path}`);
			setPost(res.data);
			//console.log(res.data);
		} catch (error) {
			//console.log('Error: ', error);
		}
	};

	if (!post) {
		// Handle loading state
		return <p>Loading...</p>;
	}

	const { id, type, readable_publish_date, title, slug, link } = post;

	return (
		<article key={id} className='row p-5'>
			<ul className=''>
				<li className='badge bg-primary'>{type}</li>
				<li className=''>{readable_publish_date}</li>
			</ul>

			<h2 className=''>{title.rendered}</h2>

			<Link to={`../wp-post/${slug}/${id}`}>
				<img src='' alt='' className=''></img>
			</Link>

			<div className=''>
				<p>
					Post originally published {''}
					<Link className='text-decoration-none' to={`${link}`} target='_blank' rel='noopener noreferrer'>
						on WordPress
					</Link>
				</p>
			</div>
		</article>
	);
}

export default WPSinglePost;

WPSinglePost.propTypes = {
	postType: PropTypes.string
};
