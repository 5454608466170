import React, { useUserData } from '../../utils/user-info';
import { Link } from 'react-router-dom';
import BackToTop from './back-to-top';

function Footer() {
	const userData = useUserData();

	return (
		<footer className='container-fluid bg-dark p-5'>
			<BackToTop />
			<nav className='row'>
				<div className='d-flex justify-content-center mb-5'>
					<li className='mx-2'>
						<Link className='mx-auto' to={`mailto:${userData.contactInfo.email}`}>
							<i className='fa-solid fa-envelope text-white' />
						</Link>
					</li>

					<li className='mx-2'>
						<Link to={userData.socialMedia.linkedin}>
							<i className='fa-brands fa-linkedin-in text-white' />
						</Link>
					</li>

					<li className='mx-2'>
						<Link to={userData.socialMedia.github}>
							<i className='fa-brands fa-github text-white' />
						</Link>
					</li>

					<li className='mx-2'>
						<Link to={userData.socialMedia.devTo}>
							<i className='fa-brands fa-dev text-white' />
						</Link>
					</li>
				</div>
			</nav>

			<p className='d-flex justify-content-center text-white'>© 2022 - {new Date().getFullYear()} - All Rights Reserved.</p>
		</footer>
	);
}

export default Footer;
