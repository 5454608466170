import React from 'react';
import PostsList from '../components/src-devto/posts-list';

function DevToPosts() {
	return (
		<main className='container m-5'>
			<div className='row p-5'>
				<h1 className='d-flex justify-content-center pb-5 text-primary display-5'>Blog</h1>
				<PostsList />
			</div>
		</main>
	);
}

export default DevToPosts;
